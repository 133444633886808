import * as React from "react";
import DefaultLayout from "../layouts/default";
import { SinglePostWrap } from "../components/LayoutComponents.stc";
import demo from "../assets/img/malanargia_galathea_schachbrett_haldenstein_070617_1.jpg";
import styled from "styled-components";
import { graphql } from "gatsby";
import { FamilieType, SeiteType } from "../components/types";
import { Col, Row } from "reactstrap";
import { StyledLink, ArticleImage, ArticleMeta } from "../components/LayoutComponents.stc";
import { Helmet } from "react-helmet";






const ErrorPage: React.FC = () => {



  let sliderItems:any = [];
  
    
  return (
    <DefaultLayout sliderItems={sliderItems}>
      <Helmet
        title={"Seite nicht gefunden - Insects"}
        
      ></Helmet>
      <div>
        <h1>Diese Seite existiert nicht</h1>
        <p>Wählen Sie ein Ziel aus der Navigation aus, um die Inhalte dieser Webseite zu erkunden.</p>
      </div>
    </DefaultLayout>
  );
};

export default ErrorPage;
